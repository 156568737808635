import React, {useEffect, useState} from "react";
import {IconButton, Popover} from "@material-ui/core";
import TextField from "@material-ui/core/TextField/TextField";
import Done from '@material-ui/icons/DoneOutlined';
import Cancel from '@material-ui/icons/ClearOutlined';
import MaterialUISelect from "../../../common/selects/MaterialUISelect";

export default function AppParamsPopover({button, appParam, projectType, setProjectType, projectTypeDropdownData, paramType, setParamType, paramTypeDropdownData, saveOrUpdate}) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [paramData, setParamData] = useState(null);
    const [comment, setComment] = useState(null);

    useEffect(() => {
        if (!appParam) {
            return;
        }
        setParamData(appParam.data);
        setComment(appParam.comment);
    }, [appParam]);


    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
        setParamData(appParam?.data);
        setComment(appParam?.comment);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setParamData(null);
        setComment(null);
    };

    const saveOrUpdateParameter = () => {
        const appParameter = {
            id: appParam?.id,
            data: paramData,
            comment: comment,
            projectType: projectType?.value,
            type: paramType?.type,
        };
        saveOrUpdate(appParameter);
        handleClose();
    };

    const popover = () => {
        return (
            <Popover id={id}
                     open={open}
                     anchorEl={anchorEl}
                     onClose={handleClose}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'right',
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'right',
                     }}
            >
                <div style={{padding: "10px", textAlign: "center"}}>
                    {appParam && appParam.id ? "Редактировать параметр" : "Новый параметр"}
                </div>
                <div style={{padding: "10px", minWidth: "305px", maxWidth: "500px"}}>
                    <MaterialUISelect
                        label="Проект"
                        dropdownData={projectTypeDropdownData}
                        selectedValue={projectType}
                        onSelectCallbackFunction={setProjectType}
                        dataField='value'
                        displayedField='value'
                        isClearable={true}
                        isDisabled={appParam?.id}
                        styles={{root: {minWidth: "150px !important", padding: "0 0 15px 0"},
                            input: {height: "30px !important"},}}
                    />
                    <MaterialUISelect
                        label="Тип"
                        dropdownData={paramTypeDropdownData}
                        selectedValue={paramType}
                        onSelectCallbackFunction={setParamType}
                        dataField='type'
                        displayedField='name'
                        isClearable={true}
                        isDisabled={appParam?.id}
                        styles={{root: {minWidth: "150px !important", padding: "0 0 15px 0"},
                            input: {height: "30px !important"},}}
                    />
                    <TextField label="Значение"
                               name="data"
                               value={paramData}
                               onChange={(e) => setParamData(e.target.value)}
                               InputLabelProps={{shrink: true}}
                               style={{width: '100%', paddingBottom: '15px'}}
                    />
                    <TextField label="Комментарий"
                               name="comment"
                               value={comment}
                               onChange={(e) => setComment(e.target.value)}
                               InputLabelProps={{shrink: true}}
                               style={{width: '100%', paddingBottom: '15px'}}
                    />
                    <br/>
                    <br/>
                    <div className="text-right">
                        <IconButton disableFocusRipple size="medium" onClick={saveOrUpdateParameter}
                                    variant="contained"
                                    title="Сохранить параметр">
                            <Done fontSize="medium"/>
                        </IconButton>
                        <IconButton disableFocusRipple size="medium" onClick={handleClose} variant="contained"
                                    title="Отмена">
                            <Cancel fontSize="medium"/>
                        </IconButton>
                    </div>
                </div>
            </Popover>
        )
    };

    return (
        <>
            <div className="" onClick={handleClick}>
                {button}
            </div>
            {anchorEl && popover()}

        </>
    );
}