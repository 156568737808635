import React from "react";
import {
    BROWSER,
    BUYOUTS,
    CLICKS,
    CVR,
    DATE_DAY,
    DATE_HOUR,
    DATE_MONTH,
    DATE_WEEK,
    DEVICE_NAME,
    DEVICE_TYPE,
    INCOME,
    IP_MASK,
    LEADS,
    MANAGER_ANALYSIS,
    MANAGER_LTV_ANALYSIS,
    MANAGER_MAIN,
    MANAGER_REFERRER,
    OFFER,
    OFFER_BILLING_TYPE,
    OFFER_CATEGORY,
    OPERATOR,
    OS,
    PARTNER_ANALYSIS,
    PARTNER_GROUP,
    PARTNER_MAIN,
    PARTNER_TYPE,
    PROJECT,
    PT_ID,
    PT_ID2,
    PT_ID3,
    REFERER_DOMAIN,
    SERVICE,
    SITE_ID,
    SMART_LINK,
    SUB_ID,
    SUBSCRIPTION_SERVICE,
    SUBSCRIPTIONS,
    TRAF_SRC,
    TRAFFICBACKS,
    UNSUBSCRIBES,
    UTILITY,
    UTILITY_TYPE,
    DAY_NUMBER,
    PAYOUT_RATE,
    ROS,
    ROYALTIES_INCOME,
    LTV75,
    LTV90,
    LTVX,
    MARGIN75,
    MARGIN90,
    MARGINX,
    ROI75,
    ROI90,
    ROIX,
    BUYOUT_FACTOR,
    FINANCE_FEE_AMOUNT,
    FINANCE_FEE,
    SUBSCRIBE_1,
    LTV_SERVICE_TYPE,
    UNSUBSCRIBES_FACTOR,
    OS_NAME,
    OFFER_V2,
    EPC,
    ACTIVE_AMOUNTS,
    I_CLICKS,
    REBILLS,
    PERCENTAGE_OF_UNSUBSCRIBES,
    PARTNER_PAYMENTS_AMOUNT,
    PRICE,
    OPERATOR_DEDUCTION_KOEFF,
    OPERATOR_KOEFF,
    PARTNER_KOEFF,
    ROYALTIES_KOEFF,
    GROSS_REVENUE, REFERRER_INCOME, MARGIN, REFERRALS_AMOUNT, EPC_WITH_TB, TB_STATUS,
} from "./constants";
import {getMoneyString, moneyCellFormat, numLocalStringCellFormat, percentageCellFormat} from "./formatUtils";
import {PAYMENTS_AMOUNT} from "./constants";
import {INCOME_MINUS_OPERATORS_REVENUE} from "./constants";
import {getServerCurrentDate} from "./dateUtils";

export const partnerStatisticGroups = [
    {label: 'По дням', value: DATE_DAY, number: 1},
    {label: 'По часам', value: DATE_HOUR},
    {label: 'По неделям', value: DATE_WEEK},
    {label: 'По месяцам', value: DATE_MONTH},
    {label: 'По оператору', value: OPERATOR},
    // {label: 'По офферу', value: OFFER},
    {label: 'По офферу', value: OFFER_V2},
    {label: 'По потоку', value: PROJECT},
    {label: 'По смарт-линку', value: SMART_LINK},
    {label: 'По типу выплаты', value: OFFER_BILLING_TYPE},
    {label: 'По тематике', value: OFFER_CATEGORY},
];
export const managerStatisticGroups = [
    {label: 'По дням', value: DATE_DAY, number: 1},
    {label: 'По часам', value: DATE_HOUR},
    {label: 'По неделям', value: DATE_WEEK},
    {label: 'По месяцам', value: DATE_MONTH},
    {label: 'По потоку', value: PROJECT},
    // {label: 'По офферу', value: OFFER},
    {label: 'По офферу', value: OFFER_V2},
    {label: 'По типу выплаты', value: OFFER_BILLING_TYPE},
    {label: 'По тематике', value: OFFER_CATEGORY},
    {label: 'По смарт-линку', value: SMART_LINK},
    {label: 'По партнеру', value: PARTNER_GROUP},
    {label: 'По типу партнера', value: PARTNER_TYPE},
    {label: 'По оператору', value: OPERATOR},
    {label: 'По услуге', value: UTILITY},
    {label: 'По типу услуги', value: UTILITY_TYPE},
    {label: 'По группе сервисов', value: SERVICE},
    {label: 'По подп. сервису', value: SUBSCRIPTION_SERVICE},
];
export const partnerTrafficAnalysisGroups = [
    {label: 'По дням', value: DATE_DAY, number: 1},
    {label: 'По часам', value: DATE_HOUR},
    {label: 'По неделям', value: DATE_WEEK},
    {label: 'По месяцам', value: DATE_MONTH},
    {label: 'По оператору', value: OPERATOR},
    // {label: 'По офферу', value: OFFER},
    {label: 'По офферу', value: OFFER_V2},
    {label: 'По потоку', value: PROJECT},
    {label: 'По смарт-линку', value: SMART_LINK},
    {label: 'По типу выплаты', value: OFFER_BILLING_TYPE}, //в старом - по типу оффера
    {label: 'По тематике', value: OFFER_CATEGORY}, //в старом - по тематике
    {label: 'По диапазону IP', value: IP_MASK},
    {label: 'По домену', value: REFERER_DOMAIN},
    {label: 'По типу устройства', value: DEVICE_TYPE},
    {label: 'По устройству', value: DEVICE_NAME},
    {label: 'По OC name', value: OS_NAME},
    {label: 'По OC version', value: OS},
    {label: 'По браузеру', value: BROWSER},
    {label: 'По pt_id', value: PT_ID},
    {label: 'По pt_id2', value: PT_ID2},
    {label: 'По pt_id3', value: PT_ID3},
    {label: 'По Sub_id', value: SUB_ID},
    {label: 'По site_id', value: SITE_ID},
    {label: 'По traf_src', value: TRAF_SRC},
    {label: 'По причине Trafficback', value: TB_STATUS},
];
export const managerTrafficAnalysisGroups = [
    {label: 'По дням', value: DATE_DAY, number: 1},
    {label: 'По часам', value: DATE_HOUR},
    {label: 'По неделям', value: DATE_WEEK},
    {label: 'По месяцам', value: DATE_MONTH},
    {label: 'По оператору', value: OPERATOR},
    // {label: 'По офферу', value: OFFER},
    {label: 'По офферу', value: OFFER_V2},
    {label: 'По потоку', value: PROJECT},
    {label: 'По смарт-линку', value: SMART_LINK},
    {label: 'По партнеру', value: PARTNER_GROUP},
    {label: 'По типу выплаты', value: OFFER_BILLING_TYPE}, //в старом - по типу оффера
    {label: 'По тематике', value: OFFER_CATEGORY}, //в старом - по тематике
    {label: 'По диапазону IP', value: IP_MASK},
    {label: 'По домену', value: REFERER_DOMAIN},
    {label: 'По типу устройства', value: DEVICE_TYPE},
    {label: 'По устройству', value: DEVICE_NAME},
    {label: 'По OC name', value: OS_NAME},
    {label: 'По OC version', value: OS},
    {label: 'По браузеру', value: BROWSER},
    {label: 'По pt_id', value: PT_ID},
    {label: 'По pt_id2', value: PT_ID2},
    {label: 'По pt_id3', value: PT_ID3},
    {label: 'По Sub_id', value: SUB_ID},
    {label: 'По site_id', value: SITE_ID},
    {label: 'По traf_src', value: TRAF_SRC},
    {label: 'По причине Trafficback', value: TB_STATUS},
];

export const getDefaultSelectedGroups = (selectedTab) => {
    return selectedTab === MANAGER_LTV_ANALYSIS
        ? [{label: 'По партнеру', value: PARTNER_GROUP, number: 1}, {label: 'По подп. сервису', value: SUBSCRIPTION_SERVICE, number: 2}]
        : [{label: 'По дням', value: DATE_DAY, number: 1}];
};

export const managerLtvAnalysisGroups = [
    {label: 'По дням', value: DATE_DAY},
    {label: 'По неделям', value: DATE_WEEK},
    {label: 'По месяцам', value: DATE_MONTH},
    {label: 'По партнеру', value: PARTNER_GROUP},
    // {label: 'По офферу', value: OFFER},
    {label: 'По офферу', value: OFFER_V2},
    {label: 'По подп. сервису', value: SUBSCRIPTION_SERVICE},
    {label: 'По оператору', value: OPERATOR},
    {label: 'По группе сервисов', value: SERVICE},
    {label: 'По типу сервисов', value: LTV_SERVICE_TYPE, number: 1},
];

export const getAvailableGroupTypes = (statisticType) => {
    switch (statisticType) {
        case PARTNER_MAIN:
            return partnerStatisticGroups.map(group => group.value);
        case MANAGER_MAIN:
            return managerStatisticGroups.map(group => group.value);
        case PARTNER_ANALYSIS:
            return partnerTrafficAnalysisGroups.map(group => group.value);
        case MANAGER_ANALYSIS:
            return managerTrafficAnalysisGroups.map(group => group.value);
        case MANAGER_LTV_ANALYSIS:
            return managerLtvAnalysisGroups.map(group => group.value);
        default:
            return [];
    }
};

export const isColumnShouldBeRounded = (columnName) => {
    return columnName === CVR || columnName === INCOME;
};

const locale = "ru-RU";

export const ltvAnalysisColumns = [
    {name: SUBSCRIPTIONS, label: "Подписки"},
    {name: BUYOUTS, label: "Выкупы"},
    {name: BUYOUT_FACTOR, label: "% Выкупа"},
    {name: UNSUBSCRIBES, label: "Отписки"},
    {name: UNSUBSCRIBES_FACTOR, label: "% Отписок"},
    {name: PAYMENTS_AMOUNT, label: "Платежи", hidden: true},
    {name: INCOME_MINUS_OPERATORS_REVENUE, label: "Выручка"},
    {name: INCOME, label: "Выплата", hidden: false},
    {name: FINANCE_FEE, label: "% Финком.", hidden: true},
    {name: FINANCE_FEE_AMOUNT, label: "Финком.", hidden: false},
    {name: ROYALTIES_INCOME, label: "Роялти"},
    {name: PAYOUT_RATE, label: "Ставка"},
    {name: DAY_NUMBER, label: "День №"},
    {name: ROS, label: "RoS"},
    {name: LTV75, label: "LTV 75"},
    {name: LTV90, label: "LTV 90"},
    {name: LTVX, label: "LTV ", ltvInterval: true},
    {name: ROI75, label: "ROI 75"},
    {name: ROI90, label: "ROI 90"},
    {name: ROIX, label: "ROI ", ltvInterval: true},
    {name: MARGIN75, label: "Маржа 75", hidden: false},
    {name: MARGIN90, label: "Маржа 90", hidden: false},
    {name: MARGINX, label: "Маржа ", ltvInterval: true, hidden: false},
];

export const columnsFormatter = {
    [ACTIVE_AMOUNTS]: (value) => value ? value.toLocaleString(locale) : "-",
    [BUYOUTS]: (value) => value ? value.toLocaleString(locale) : "0",
    [CLICKS]: (value, rowData, isLoggedAsPartner, onClick) => value ? onClick ? <a href="#" onClick={onClick}>{value.toLocaleString(locale)}</a> : value.toLocaleString(locale) : "0",
    [CVR]: (value) => value ? value.toFixed(2).toLocaleString(locale) + "%" : "0.00%",
    [I_CLICKS]: (value) => value ? value.toLocaleString(locale) : "0",
    [INCOME]: (value, rowData, isLoggedAsPartner) => !value ? 0 : (isLoggedAsPartner) ? getMoneyString(value).replace(",", ".") : Math.round(value).toLocaleString(locale),
    [REBILLS]: (value) => value ? value.toLocaleString(locale) : "0",
    [SUBSCRIPTIONS]: (value) => value ? value.toLocaleString(locale) : "0",
    [TRAFFICBACKS]: (value, rowData, isLoggedAsPartner, onClick) => value ? onClick ? <a href="#" onClick={onClick}>{value.toLocaleString(locale)}</a> : value.toLocaleString(locale) : "0",
    [UNSUBSCRIBES]: (value) => value ? value.toLocaleString(locale) : "0",
    [PERCENTAGE_OF_UNSUBSCRIBES]: (value) => value ? value.toFixed(2).toLocaleString(locale) + "%" : "-",
    [UNSUBSCRIBES_FACTOR]: (value) => isFinite(value) ? value.toFixed(2).toLocaleString(locale) + "%" : "-",
    [LEADS]: (value) => value ? value.toLocaleString(locale) : "0",
    [PAYMENTS_AMOUNT]: (value) => value ? value.toLocaleString(locale) : "0",
    [PARTNER_PAYMENTS_AMOUNT]: (value) => value ? value.toLocaleString(locale) : "0",
    [PRICE]: (value) => value ? value.toLocaleString(locale) : "-",
    [OPERATOR_DEDUCTION_KOEFF]: (value) => value ? value.toFixed(1).toLocaleString(locale) + "%" : "-",
    [OPERATOR_KOEFF]: (value) => value ? value.toFixed(2).toLocaleString(locale) + "%" : "-",
    [PARTNER_KOEFF]: (value) => value ? value.toFixed(2).toLocaleString(locale) + "%" : "-",
    [ROYALTIES_KOEFF]: (value) => value ? value.toFixed(2).toLocaleString(locale) + "%" : "-",
    [GROSS_REVENUE]: (value) => value ?  Math.round(value).toLocaleString(locale) : 0,
    [INCOME_MINUS_OPERATORS_REVENUE]: (value) => value ?  Math.round(value).toLocaleString(locale) : 0,
    [REFERRER_INCOME]: (value) => value ?  Math.round(value).toLocaleString(locale) : 0,
    [ROYALTIES_INCOME]: (value) => value ?  Math.round(value).toLocaleString(locale) : 0,
    [MARGIN]: (value) => value ?  Math.round(value).toLocaleString(locale) : 0,
    [FINANCE_FEE_AMOUNT]: (value) => value ?  Math.round(value).toLocaleString(locale) : 0,
    [FINANCE_FEE]: (value) => value ? value.toFixed(2).toLocaleString(locale) + "%" : "-",
    [REFERRALS_AMOUNT]: (value, rowData, isLoggedAsPartner, onClick) => value ? onClick ? <a href="#" onClick={onClick}>{value.toLocaleString(locale)}</a> : value.toLocaleString(locale) : "0",
    [BUYOUT_FACTOR]: (value) => value ? value.toFixed(1).toLocaleString(locale) + "%" : "-",
    [DAY_NUMBER]: (value) => value ? value.toLocaleString(locale) : "-",
    [PAYOUT_RATE]: (value) => isFinite(value) ? value.toFixed(2) : "-",
    [ROS]: (value) => isFinite(value) ? value.toFixed(2) : "-",
    [LTV75]: (value) => isFinite(value) ? value.toFixed(1) : "-",
    [LTV90]: (value) => isFinite(value) ? value.toFixed(1) : "-",
    [LTVX]: (value) => isFinite(value) ? value.toFixed(1) : "-",
    [ROI75]: (value) => isFinite(value) ? getStyledRoi(value) : "-",
    [ROI90]: (value) => isFinite(value) ? getStyledRoi(value) : "-",
    [ROIX]: (value) => isFinite(value) ? getStyledRoi(value) : "-",
    [MARGIN75]: (value) => isFinite(value) ? getStyledMargin(value) : "-",
    [MARGIN90]: (value) => isFinite(value) ? getStyledMargin(value) : "-",
    [MARGINX]: (value) => isFinite(value) ? getStyledMargin(value) : "-",
    [EPC]: (value, rowData) => value ? (rowData[EPC_WITH_TB].toFixed(2).toLocaleString(locale) + " | " + value.toFixed(2).toLocaleString(locale)) : "-",
};


const getStyledRoi = (value) => {
    const color = value >= 0.10 ? "#4a9f3b" : value < 0 ? "#f75520" : "#dcb010"; // green | red | yellow colors
    return <div style={{fontWeight: "bold", color: color}} className="no_left_right_padding">{(value * 100).toFixed(1).toLocaleString(locale) + "%"}</div>;
};

const getStyledMargin = (value) => {
    const style = value < 0 ? {fontWeight: "bold", color:"#f75520"} : {}; // red color
    return <div style={style} className="no_left_right_padding">{Math.round(value).toLocaleString(locale)}</div>;
};

export const xlsxColumnsFormatter = {
    [ACTIVE_AMOUNTS]: (value) => value ? value : "-",
    [BUYOUTS]: (value) => value ? value : 0,
    [CLICKS]: (value) => value ? value : 0,
    [CVR]: (value) => value ? value * 0.01 : 0,
    [I_CLICKS]: (value) => value ? value : 0,
    [INCOME]: (value) => value ? value : 0.00,
    [REBILLS]: (value) => value ? value : 0,
    [SUBSCRIPTIONS]: (value) => value ? value : 0,
    [TRAFFICBACKS]: (value) => value ? value : 0,
    [UNSUBSCRIBES]: (value) => value ? value : 0,
    [PERCENTAGE_OF_UNSUBSCRIBES]: (value) => value ? value : 0,
    [UNSUBSCRIBES_FACTOR]: (value) => isFinite(value) ? value : "-",
    [LEADS]: (value) => value ? value : 0,
    [PAYMENTS_AMOUNT]: (value) => value ? value : 0,
    [PARTNER_PAYMENTS_AMOUNT]: (value) => value ? value : 0,
    [PRICE]: (value) => value ? value : "-",
    [OPERATOR_DEDUCTION_KOEFF]: (value) => value ? value * 0.01 : "-",
    [OPERATOR_KOEFF]: (value) => value ? value * 0.01  : "-",
    [PARTNER_KOEFF]: (value) => value ? value * 0.01  : "-",
    [ROYALTIES_KOEFF]: (value) => value ? value * 0.01  : "-",
    [GROSS_REVENUE]: (value) => value ? value : 0.00,
    [INCOME_MINUS_OPERATORS_REVENUE]: (value) => value ? value : 0.00,
    [REFERRER_INCOME]: (value) => value ? value : 0.00,
    [ROYALTIES_INCOME]: (value) => value ? value : 0.00,
    [MARGIN]: (value) => value ? value : 0.00,
    [FINANCE_FEE_AMOUNT]: (value) => isFinite(value) ? value : "-",
    [FINANCE_FEE]: (value) => value ? isFinite(value) : "-",
    [REFERRALS_AMOUNT]: (value) => value ? value : "-",
    [BUYOUT_FACTOR]: (value) => isFinite(value) ? value : "-",
    [DAY_NUMBER]: (value) => isFinite(value) ? value : "-",
    [PAYOUT_RATE]: (value) => isFinite(value) ? value : "-",
    [ROS]: (value) => isFinite(value) ? value : "-",
    [LTV75]: (value) => isFinite(value) ? value : "-",
    [LTV90]: (value) => isFinite(value) ? value : "-",
    [LTVX]: (value) => isFinite(value) ? value : "-",
    [ROI75]: (value) => isFinite(value) ? value : "-",
    [ROI90]: (value) => isFinite(value) ? value : "-",
    [ROIX]: (value) => isFinite(value) ? value : "-",
    [MARGIN75]: (value) => isFinite(value) ? value : "-",
    [MARGIN90]: (value) => isFinite(value) ? value : "-",
    [MARGINX]: (value) => isFinite(value) ? value : "-",
    [EPC]: (value, row) => value ? (row[EPC_WITH_TB].toFixed(2).toLocaleString(locale) + " | " +  value.toFixed(2).toLocaleString(locale)) : "-",
};

export const xlsxCellNumFormat = {
    [ACTIVE_AMOUNTS]: (value) => numLocalStringCellFormat(value),
    [BUYOUTS]: (value) => numLocalStringCellFormat(value),
    [CLICKS]: (value) => numLocalStringCellFormat(value),
    [CVR]:  () => percentageCellFormat(),
    [I_CLICKS]: (value) => numLocalStringCellFormat(value),
    [INCOME]: (value) => moneyCellFormat(value),
    [REBILLS]: (value) => numLocalStringCellFormat(value),
    [SUBSCRIPTIONS]: (value) => numLocalStringCellFormat(value),
    [TRAFFICBACKS]: (value) => numLocalStringCellFormat(value),
    [UNSUBSCRIBES]: (value) => numLocalStringCellFormat(value),
    [PERCENTAGE_OF_UNSUBSCRIBES]: (value) => percentageCellFormat(value),
    [LEADS]: (value) => numLocalStringCellFormat(value),
    [PAYMENTS_AMOUNT]: (value) => numLocalStringCellFormat(value),
    [PARTNER_PAYMENTS_AMOUNT]: (value) => numLocalStringCellFormat(value),
    [PRICE]: (value) => moneyCellFormat(value),
    [OPERATOR_DEDUCTION_KOEFF]:  () => percentageCellFormat(),
    [OPERATOR_KOEFF]: () => percentageCellFormat(),
    [PARTNER_KOEFF]: () => percentageCellFormat(),
    [ROYALTIES_KOEFF]: () => percentageCellFormat(),
    [GROSS_REVENUE]: (value) => moneyCellFormat(value),
    [INCOME_MINUS_OPERATORS_REVENUE]: (value) => moneyCellFormat(value),
    [REFERRER_INCOME]: (value) => moneyCellFormat(value),
    [ROYALTIES_INCOME]: (value) => moneyCellFormat(value),
    [MARGIN]: (value) => moneyCellFormat(value),
    [REFERRALS_AMOUNT]: (value) => numLocalStringCellFormat(value),
    [DAY_NUMBER]: (value) => numLocalStringCellFormat(value),
    [BUYOUT_FACTOR]: (value) => moneyCellFormat(value),
    [PAYOUT_RATE]: (value) => moneyCellFormat(value),
    [ROS]: (value) => numLocalStringCellFormat(value),
    [LTV75]: (value) => moneyCellFormat(value),
    [LTV90]: (value) => moneyCellFormat(value),
    [LTVX]: (value) => moneyCellFormat(value),
    [ROI75]: () => percentageCellFormat(),
    [ROI90]: () => percentageCellFormat(),
    [ROIX]: () => percentageCellFormat(),
    [EPC]: () => moneyCellFormat(),
};


export const dateColumns = [DATE_DAY, DATE_HOUR, DATE_WEEK, DATE_MONTH];

export const isDateColumn = (columnValue) => {
    return columnValue && dateColumns.includes(columnValue);
};

export const getStatisticGroupName = (groupType, selectedTab) => {
    return selectedTab === MANAGER_REFERRER ? "Партнёр" : (groupType && groupTypes[groupType]);
};
const groupTypes = {
    DATE_DAY: "Дни",
    DATE_HOUR: "Часы",
    DATE_WEEK: "Недели",
    DATE_MONTH: "Месяцы",
    PROJECT: "Потоки",
    SMART_LINK: "Смартлинки",
    SERVICE: "Группы сервисов",
    OFFER: "Офферы",
    OFFER_V2: "Офферы",
    OPERATOR: "Операторы",
    OFFER_CATEGORY: "Тематика",
    OFFER_BILLING_TYPE: "Тип выплаты",
    DEVICE_TYPE: "Типы устройств",
    REFERER_DOMAIN: "Домены",
    DEVICE_NAME: "Названия устройств",
    OS: "Операционные системы",
    BROWSER: "Браузеры",
    PT_ID: "ID",
    PT_ID2: "ID2",
    PT_ID3: "ID3",
    SUB_ID: "SUB_ID",
    IP_MASK: "IP адреса",
    PARTNER: "Партнеры",
    PARTNER_TYPE: "Типы партнеров",
    CHANNEL: "Каналы",
    UTILITY: "Услуги",
    UTILITY_TYPE: "Типы услуг",
    SUBSCRIPTION_SERVICE: "Подписочные сервисы",
    SITE_ID: "SITE_ID",
    TRAF_SRC: "TRAF_SRC",
    LTV_SERVICE_TYPE: "Тип сервиса",
};

const columnResultDispatcher = {
    [CLICKS]: (tableData, columnName) => sumNumData(tableData, columnName),
    [SUBSCRIPTIONS]: (tableData, columnName) => sumNumData(tableData, columnName),
    [TRAFFICBACKS]: (tableData, columnName) => sumNumData(tableData, columnName),
    [CVR]: (tableData, columnName) => 0.00,
    [I_CLICKS]: (tableData, columnName) => sumNumData(tableData, columnName),
    [BUYOUTS]: (tableData, columnName) => sumNumData(tableData, columnName),
    [ACTIVE_AMOUNTS]: (tableData, columnName) => null,
    [UNSUBSCRIBES]: (tableData, columnName) => sumNumData(tableData, columnName),
    [PERCENTAGE_OF_UNSUBSCRIBES]: (tableData, columnName) => 0.00,
    [INCOME]: (tableData, columnName) => sumNumData(tableData, columnName),
    [LEADS]: (tableData, columnName) => sumNumData(tableData, columnName),
    [PAYMENTS_AMOUNT]: (tableData, columnName) => sumNumData(tableData, columnName),
    [PARTNER_PAYMENTS_AMOUNT]: (tableData, columnName) => sumNumData(tableData, columnName),
    [REBILLS]: (tableData, columnName) => sumNumData(tableData, columnName),
    [PRICE]: (tableData, columnName) => returnFirstValueIfEqualsOrNull(tableData, columnName),
    [OPERATOR_DEDUCTION_KOEFF]: (tableData, columnName) => returnFirstValueIfEqualsOrNull(tableData, columnName),
    [OPERATOR_KOEFF]: (tableData, columnName) => returnFirstValueIfEqualsOrNull(tableData, columnName),
    [PARTNER_KOEFF]: (tableData, columnName) => returnFirstValueIfEqualsOrNull(tableData, columnName),
    [ROYALTIES_KOEFF]: (tableData, columnName) => returnFirstValueIfEqualsOrNull(tableData, columnName),
    [GROSS_REVENUE]: (tableData, columnName) => sumNumData(tableData, columnName),
    [INCOME_MINUS_OPERATORS_REVENUE]: (tableData, columnName) => sumNumData(tableData, columnName),
    [REFERRER_INCOME]: (tableData, columnName) => sumNumData(tableData, columnName),
    [ROYALTIES_INCOME]: (tableData, columnName) => sumNumData(tableData, columnName),
    [MARGIN]: (tableData, columnName) => sumNumData(tableData, columnName),
    [REFERRALS_AMOUNT]: (tableData, columnName) => sumNumData(tableData, columnName),
    [DAY_NUMBER]: (tableData, columnName) => returnFirstValueIfEqualsOrNull(tableData, columnName),
    [FINANCE_FEE_AMOUNT]: (tableData, columnName) => sumNumData(tableData, columnName),
    [EPC]: (tableData, columnName) => 0.00,
    [EPC_WITH_TB]: (tableData, columnName) => 0.00,
};

export const sumTableData = (tableData, columnName) => {
    return columnResultDispatcher[columnName]?.(tableData, columnName);
};

const sumNumData = (tableData, columnName) => {
    return tableData.map(item => item[columnName]).reduce((prev, curr) => prev + curr, 0);
};

const returnFirstValueIfEqualsOrNull = (tableData, columnName) => {
    if (!tableData || tableData.length === 0 || !columnName) {
        return null;
    }
    const firstValue = tableData[0][columnName];
    tableData.forEach(item => {
        if (item[columnName] !== firstValue) {
            return null;
        }
    });
    return firstValue;
};

export const calcSubscriptionsNum = (item, statisticType) => {
    switch (statisticType){
        case MANAGER_ANALYSIS:
        case PARTNER_ANALYSIS:
            return item[LEADS];
        case PARTNER_MAIN:
            return item[SUBSCRIPTIONS] + item[BUYOUTS];
    }
    return item[SUBSCRIPTIONS];
};

export const getCR = (resultData, statisticType) => {
    if (!resultData || !statisticType) {
        return 0.00;
    }
    let numerator;
    let denominator;
    switch (statisticType) {
        case PARTNER_MAIN:
            numerator = (resultData[SUBSCRIPTIONS] + resultData[BUYOUTS]);
            denominator = (resultData[CLICKS] - resultData[TRAFFICBACKS]);
            break;
        case MANAGER_MAIN:
            numerator = resultData[SUBSCRIPTIONS];
            denominator = (resultData[CLICKS] - resultData[TRAFFICBACKS]);
            break;
        case PARTNER_ANALYSIS:
        case MANAGER_ANALYSIS:
            numerator = resultData[LEADS];
            denominator = resultData[CLICKS];
            break;
        default:
            numerator = 0;
    }
    let cvr = !numerator
        ? 0.00
        : !denominator
            ? 100.00
            : 100 * (numerator / denominator);
    return Math.round(cvr * 100) / 100;
};

export const getEPC = (resultData, statisticType, withTb) => {
    if (!resultData || !statisticType || statisticType !== MANAGER_MAIN || !resultData[CLICKS] || resultData[CLICKS] === 0) {
        return 0.00;
    }
    return withTb ? (resultData[INCOME] / (resultData[CLICKS] - resultData[TRAFFICBACKS])) : (resultData[INCOME] / resultData[CLICKS]);
};

export const getPercentageOfUnsubscribes = (resultData, statisticType) => {
    if (!resultData || !statisticType) {
        return 0.00;
    }
    let numerator;
    let denominator;
    switch (statisticType) {
        case MANAGER_MAIN:
            numerator = resultData[UNSUBSCRIBES];
            denominator = (resultData[SUBSCRIPTIONS]);
            break;
        case PARTNER_MAIN:
        case PARTNER_ANALYSIS:
        case MANAGER_ANALYSIS:
            break;
        default:
            numerator = 0;
    }
    let percentageOfUnsubscribes = !numerator
        ? 0.00
        : !denominator
            ? 100.00
            : 100 * (numerator / denominator);
    return Math.round(percentageOfUnsubscribes * 100) / 100;
};

export const calculateLtvRow = (row, pageState, ltvServiceTypes, ltvServiceType, dayNumber) => {
    if (!ltvServiceType){
        ltvServiceType = row.groupData?.ltvServiceTypeId
            ? ltvServiceTypes.find( item => item.id === row.groupData.ltvServiceTypeId)
            : pageState.filters.ltvServiceType;
    }
   if (row.groupData.from && row.groupName.match(/^\d\d\d\d-\d\d-\d\d$/)){ // если группировка по дням
        dayNumber = Math.ceil((getServerCurrentDate().getTime() - row.groupData?.from) / 1000 / 60 / 60 / 24);
   }

    if (row.nestedData){
        row.nestedData.forEach(nestedRow => {calculateLtvRow(nestedRow, pageState, ltvServiceTypes, ltvServiceType, dayNumber)});
        calculateLtvSubtotal(row, row.nestedData, pageState.filters, dayNumber);
        return;
    }
    const rosLine = ltvServiceType?.line;
    const buyoutRate = pageState.filters.buyoutRate;
    const financeFeeFilter = pageState.filters.financeFee ? 1 + parseFloat(pageState.filters.financeFee) / 100 : NaN;

    row[DAY_NUMBER] = dayNumber;
    row[ROS] = row[PAYMENTS_AMOUNT] / row[SUBSCRIPTIONS];
    row[UNSUBSCRIBES_FACTOR] = 100 * row[UNSUBSCRIBES] / row [SUBSCRIPTIONS];
    row[BUYOUT_FACTOR] = 100 * row[BUYOUTS] / row[SUBSCRIPTIONS];

    if( rosLine?.length > 0 ) {
        const calculateLtv = (dayNumber) => rosK * rosLine[dayNumber - 1] * (row[INCOME_MINUS_OPERATORS_REVENUE] - row[ROYALTIES_INCOME]) / row[PAYMENTS_AMOUNT];
        const rosK = row[ROS] / rosLine[row[DAY_NUMBER] - 1];

        row[LTV75] = calculateLtv(75);
        row[LTV90] = calculateLtv(90);
        row[LTVX] = calculateLtv(pageState.ltvInterval);
    }

    if (pageState.filters.payoutType?.value || +buyoutRate) {
        const financeFee = financeFeeFilter ? financeFeeFilter : (1 + row[FINANCE_FEE_AMOUNT] / row[INCOME]);
        const isBuyouts = pageState.filters.payoutType?.value === SUBSCRIBE_1;
        const calculateRoi = (ltv) => {
            return +buyoutRate
                ? (row[SUBSCRIPTIONS] * ltv) / (buyoutRate * (isBuyouts ? row[BUYOUTS] : row[SUBSCRIPTIONS]) * financeFee) - 1
                : (isBuyouts? (row[SUBSCRIPTIONS] * ltv) : (row[INCOME_MINUS_OPERATORS_REVENUE] - row[ROYALTIES_INCOME])) / (row[INCOME] * financeFee) - 1;
        }
        const calculateMargin = (ltvId) => {
            return +buyoutRate
                ? row[ltvId] * row[SUBSCRIPTIONS] - (buyoutRate * (isBuyouts ? row[BUYOUTS] : row[SUBSCRIPTIONS]) * financeFee)
                : row[ltvId] * row[SUBSCRIPTIONS] - row[ROYALTIES_INCOME] - row[INCOME] * financeFee;
        };

        row[PAYOUT_RATE] = row[INCOME] / (isBuyouts ? row[BUYOUTS] : row[PAYMENTS_AMOUNT]);
        row[ROI75] = calculateRoi(row[LTV75]);
        row[ROI90] = calculateRoi(row[LTV90]);
        row[ROIX] = calculateRoi(row[LTVX]);
        row[MARGIN75] = calculateMargin(LTV75);
        row[MARGIN90] = calculateMargin(LTV90);
        row[MARGINX] = calculateMargin(LTVX);
    }
    else {
        row[PAYOUT_RATE] = NaN;
        row[ROI75] = NaN;
        row[ROI90] = NaN;
        row[ROIX] = NaN;
    }
};

export const calculateLtvSubtotal = (subtotalRow, tableData, filters, dayNumber) => {
    subtotalRow[DAY_NUMBER] = dayNumber;
    subtotalRow[ROS] = subtotalRow[PAYMENTS_AMOUNT] / subtotalRow[SUBSCRIPTIONS];
    subtotalRow[UNSUBSCRIBES_FACTOR] = 100 * subtotalRow[UNSUBSCRIBES] / subtotalRow[SUBSCRIPTIONS];
    subtotalRow[BUYOUT_FACTOR] = 100 * subtotalRow[BUYOUTS] / subtotalRow[SUBSCRIPTIONS];
    subtotalRow[FINANCE_FEE] = 100 * subtotalRow[FINANCE_FEE_AMOUNT] / subtotalRow[INCOME];

    const financeFee = filters.financeFee ? (1 + parseFloat(filters.financeFee) / 100) : (1 + subtotalRow[FINANCE_FEE_AMOUNT] / subtotalRow[INCOME]);
    const calculateLtv = (ltvId) => tableData.reduce((sum, row) => !sum && row[ltvId] ? row[SUBSCRIPTIONS] * row[ltvId] : (sum + (row[SUBSCRIPTIONS] > 0 && row[ltvId] ? row[SUBSCRIPTIONS] * row[ltvId] : 0)), NaN) / subtotalRow[SUBSCRIPTIONS];

    subtotalRow[LTV75] = calculateLtv(LTV75);
    subtotalRow[LTV90] = calculateLtv(LTV90);
    subtotalRow[LTVX] = calculateLtv(LTVX);
    if (filters.payoutType?.value || +filters.buyoutRate) {
        const isBuyouts = filters.payoutType?.value === SUBSCRIBE_1;
        const calculateRoi = (ltvId) => +filters.buyoutRate
            ? (subtotalRow[ltvId] * subtotalRow[SUBSCRIPTIONS]) / (filters.buyoutRate * (isBuyouts ? subtotalRow[BUYOUTS] : subtotalRow[SUBSCRIPTIONS]) * financeFee) - 1
            : (isBuyouts ? subtotalRow[ltvId] * subtotalRow[SUBSCRIPTIONS] : (subtotalRow[INCOME_MINUS_OPERATORS_REVENUE] - subtotalRow[ROYALTIES_INCOME])) / (subtotalRow[INCOME] * financeFee) - 1;
        const calculateMargin = (ltvId) => +filters.buyoutRate
            ? subtotalRow[ltvId] * subtotalRow[SUBSCRIPTIONS] - (filters.buyoutRate * (isBuyouts ? subtotalRow[BUYOUTS] : subtotalRow[SUBSCRIPTIONS]) * financeFee)
            : subtotalRow[ltvId] * subtotalRow[SUBSCRIPTIONS] - subtotalRow[ROYALTIES_INCOME] - subtotalRow[INCOME] * financeFee;

        subtotalRow[PAYOUT_RATE] = subtotalRow[INCOME] / (isBuyouts ? subtotalRow[BUYOUTS] : subtotalRow[PAYMENTS_AMOUNT]);
        subtotalRow[ROI75] = calculateRoi(LTV75);
        subtotalRow[ROI90] = calculateRoi(LTV90);
        subtotalRow[ROIX] = calculateRoi(LTVX);
        subtotalRow[MARGIN75] = calculateMargin(LTV75);
        subtotalRow[MARGIN90] = calculateMargin(LTV90);
        subtotalRow[MARGINX] = calculateMargin(LTVX);
    }
};
