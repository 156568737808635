import React, {useEffect} from "react";
import SmallNavTab from "./SmallNavTab";
import {connect, useSelector} from "react-redux";
import {loadPartnerCaps} from "../../../actions/capActions";
import useResizeObserver from "../../../hooks/useResizeObserver";

function SmallNavTabs({tabs, selectedTabName, onSelectTab, additionalElements, tabsData, loadPartnerCaps}) {

    const scrollableObserver = useResizeObserver(document.getElementById("scrollable"));

    let tabsWidth = tabs && Object.keys(tabs)
        .map(tabName => (tabs[tabName].length * 14) + 15)
        .reduce((accumulator, currentValue) => {return accumulator + currentValue},0);

    const contentHeaderWidth = scrollableObserver?.width;

    tabsWidth = !contentHeaderWidth || !tabsWidth || (tabsWidth < contentHeaderWidth)
        ? tabsWidth
        : contentHeaderWidth;


    const auth = useSelector(state => state.auth);
    const {isAdminAsPartner, isPartner} = auth;
    const isPartnerPage = isAdminAsPartner || isPartner;
    useEffect(() => {
        isPartnerPage && loadPartnerCaps();
    }, []);


    return (
        <>
            <div className="nav nav-tabs" id="nav-tab" role="tablist" style={{width: tabsWidth + 'px'}}>
                {tabs && Object.keys(tabs).map(tabName =>
                    <SmallNavTab key={tabName} label={tabs[tabName]} selected={tabName === selectedTabName}
                                 onSelect={() => onSelectTab(tabName)} id={tabsData && tabsData[tabName].id}/>)}
                {isPartnerPage}
                {additionalElements}
            </div>
        </>
    )
}

export default connect(null, {loadPartnerCaps})(SmallNavTabs);
