import {
    APP_PARAM_TYPES_LOADED,
    APP_PARAM_TYPES_LOADING, APP_PARAMS_CLEARED,
    APP_PARAMS_LOADED,
    APP_PARAMS_LOADING
} from "../../actions/actionTypes";

const initialState = {
    appParamTypes: [],
    appParamTypesLoading: false,
    appParams: [],
    appParamsLoading: false
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case APP_PARAM_TYPES_LOADING:
            return {
                ...state,
                appParamTypesLoading: true
            };
        case APP_PARAM_TYPES_LOADED:
            return {
                ...state,
                appParamTypes: action.payload,
                appParamTypesLoading: false
            };
        case APP_PARAMS_LOADING:
            return {
                ...state,
                appParamsLoading: true
            };
        case APP_PARAMS_LOADED:
            return {
                ...state,
                appParams: action.payload,
                appParamsLoading: false
            };
        case APP_PARAMS_CLEARED:
            return {
                ...state,
                appParams: [],
                appParamsLoading: false
            };
        default:
            return state;
    }
}