import {
    ACTIVE_OFFERS,
    ADVERTISING_NETWORKS,
    ALL_OFFERS,
    API_DOC_TAB_BANNERS,
    API_DOC_TAB_BLACK_LIST,
    API_DOC_TAB_CAP,
    API_DOC_TAB_DOMAINS,
    API_DOC_TAB_FILES,
    API_DOC_OTHER,
    API_DOC_TAB_IPS,
    API_DOC_TAB_POSTBACK,
    API_DOC_TAB_POSTBACK_RESENDING,
    API_DOC_TAB_S2S,
    API_DOC_TAB_TRAFFIC_FILTERS,
    API_DOC_TAB_TRAFFICBACK,
    CONNECTED_OFFERS,
    DEPRECATED_OFFERS,
    FAQ_TAB_BEGINNING,
    FAQ_TAB_COMMON_INFO,
    FAQ_TAB_CONTACTS,
    FAQ_TAB_OPERATOR_RESTRICTIONS,
    FAQ_TAB_PAYMENTS,
    INACTIVE_OFFERS,
    MANAGER_ANALYSIS,
    MANAGER_LTV_ANALYSIS,
    MANAGER_MAIN, MANAGER_PAYMENTS,
    MANAGER_REFERRER, OFFER_BUYOUT_RULES,
    OFFER_EDIT_MAIN,
    OFFER_EDIT_INCOMES,
    PARTNER_ANALYSIS,
    PARTNER_MAIN,
    SMARTLINKS, API_DOC_TAB_LOGS,
    API_DOC_APP_PARAMS
} from "./constants";

export const managerPaymentPageTabs = {
    [MANAGER_PAYMENTS]: "Выплаты",
    [ADVERTISING_NETWORKS]: "Рекламные сети",
};

export const partnerStatisticPageTabs = {
    [PARTNER_MAIN]: "Статистика",
    [PARTNER_ANALYSIS]: "Анализ трафика"
};

export const managerStatisticMobilePageTabs = {
    [MANAGER_MAIN]: "Статистика",
    [MANAGER_ANALYSIS]: "Трафик",
    [MANAGER_REFERRER]: "Рефералы",
    [MANAGER_LTV_ANALYSIS]: "LTV"
};

export const managerStatisticDesktopPageTabs = {
    [MANAGER_MAIN]: "Статистика",
    [MANAGER_ANALYSIS]: "Анализ трафика",
    [MANAGER_REFERRER]: "Рефералы",
    [MANAGER_LTV_ANALYSIS]: "Анализ LTV"
};

export const partnerOfferTabs = {
    [ALL_OFFERS]: "Все",
    [CONNECTED_OFFERS]: "Подключенные",
    [SMARTLINKS]: "Смартлинки",
};

export const managerOfferTabs = {
    [ACTIVE_OFFERS]: "Активные",
    [INACTIVE_OFFERS]: "Неактивные",
    [DEPRECATED_OFFERS]: "Устаревшие",
    [OFFER_BUYOUT_RULES]: "Правила выкупа",
};

export const offerEditTabs = {
    [OFFER_EDIT_MAIN]: "Настройки оффера",
    [OFFER_EDIT_INCOMES]: "Настройкии выплат",
};

export const offerEditTabsWithoutIncomes = {
    [OFFER_EDIT_MAIN]: "Настройки оффера",
};

export const faqPageTabs = {
    [FAQ_TAB_COMMON_INFO]: "Общая информация",
    [FAQ_TAB_BEGINNING]: "Начало работы",
    [FAQ_TAB_OPERATOR_RESTRICTIONS]: "Ограничения операторов",
    [FAQ_TAB_PAYMENTS]: "Выплаты",
    [FAQ_TAB_CONTACTS]: "Контакты"
};

export const faqPageContentTypeMapping = {
    [FAQ_TAB_COMMON_INFO]: "COMMON_INFO",
    [FAQ_TAB_BEGINNING]: "BEGINNING_OF_WORK",
    [FAQ_TAB_OPERATOR_RESTRICTIONS]: "OPERATOR_RESTRICTIONS",
    [FAQ_TAB_PAYMENTS]: "PAYMENTS",
    [FAQ_TAB_CONTACTS]: "CONTACTS",
};

export const toolsPageAdminTabs = {
    [API_DOC_TAB_TRAFFIC_FILTERS]: "Фильтры трафика",
    [API_DOC_TAB_TRAFFICBACK]: "Trafficback",
    [API_DOC_TAB_POSTBACK]: "Postback",
    [API_DOC_TAB_S2S]: "S2S",
    [API_DOC_TAB_IPS]: "Диапазоны IP",
    [API_DOC_TAB_BLACK_LIST]: "Black list",
    [API_DOC_TAB_CAP]: "CAP",
    [API_DOC_TAB_POSTBACK_RESENDING]: "Переотправка постбеков",
    [API_DOC_TAB_FILES]: "Файлы/документы",
    [API_DOC_TAB_BANNERS]: "Баннеры",
    [API_DOC_TAB_LOGS]: "Логи",
    [API_DOC_APP_PARAMS]: "Параметры приложений",
    [API_DOC_OTHER]: "Разное",
};

export const toolsPagPartnerTabs = {
    [API_DOC_TAB_TRAFFIC_FILTERS]: "Фильтры трафика",
    [API_DOC_TAB_TRAFFICBACK]: "Trafficback",
    [API_DOC_TAB_POSTBACK]: "Postback",
    [API_DOC_TAB_S2S]: "S2S",
    [API_DOC_TAB_IPS]: "Диапазоны IP",
    [API_DOC_TAB_BLACK_LIST]: "Black list",
    [API_DOC_TAB_DOMAINS]: "Парковка доменов",
};

export const toolsPageTabsDataMapping = {
    [API_DOC_TAB_TRAFFIC_FILTERS]: {contentType: "TRAFFIC_FILTERS", searchParam: "filters", id: "home-tab"},
    [API_DOC_TAB_TRAFFICBACK]: {contentType: "TRAFFICBACK", searchParam: "trafficback", id: "start-tab"},
    [API_DOC_TAB_POSTBACK]: {contentType: "POSTBACK", searchParam: "postback", id: "oss-tab"},
    [API_DOC_TAB_S2S]: {contentType: "S2S", searchParam: "s2s", id: "pay-tab"},
    [API_DOC_TAB_IPS]: {contentType: "IP_RANGE", searchParam: "iprange", id: "ip-tab"},
    [API_DOC_TAB_BLACK_LIST]: {contentType: "BLACK_LIST", searchParam: "blacklist", id: "blacklist-tab"},
    [API_DOC_TAB_DOMAINS]: {contentType: "DOMAINS", searchParam: "domains", id: "domains-tab"},
    [API_DOC_TAB_CAP]: {contentType: "CAP", searchParam: "cap", id: "cap-tab"},
    [API_DOC_TAB_POSTBACK_RESENDING]: {contentType: "POSTBACK_RESENDING", searchParam: "postbackResending", id: "postbackResending-tab"},
    [API_DOC_TAB_FILES]: {contentType: "API_DOC_TAB_FILES", searchParam: "files", id: "files-tab"},
    [API_DOC_TAB_BANNERS]: {contentType: "API_DOC_TAB_BANNERS", searchParam: "banners", id: "banners-tab"},
    [API_DOC_TAB_LOGS]: {contentType: "API_DOC_TAB_LOGS", searchParam: "logs", id: "logs-tab"},
    [API_DOC_OTHER]: {contentType: "API_DOC_OTHER", searchParam: "otherTools", id: "other-tools-tab"},
    [API_DOC_APP_PARAMS]: {contentType: "API_DOC_APP_PARAMS", searchParam: "appParams", id: "app-params-tab"},
};

export const toolsPageEditableTabs = [API_DOC_TAB_TRAFFIC_FILTERS, API_DOC_TAB_TRAFFICBACK, API_DOC_TAB_POSTBACK, API_DOC_TAB_S2S];


