import React, {useState} from "react";
import DatePicker from "../../../common/pickers/DatePicker";
import MaterialUiInput from "../../../common/inputs/MaterialUiInput";
import CircleButtonsGroup from "../../../common/buttons/CircleButtonsGroup";
import {LOG_S2S} from "../../../../utils/constants";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import {connect} from "react-redux";
import {loadLogs, resetLogs} from "../../../../actions/logsActions";
import Spinner from "../../../common/Spinner";
import {emptyString} from "../../../../utils/stringUtils";

function LogsContent({logsData, loadLogs, loading, resetLogs}) {

    const DEFAULT_ROW_LIMIT = "20";
    const [searchString, setSearchString] = useState("");
    const [logType, setLogType] = useState({value: LOG_S2S});
    const [searchDate, setSearchDate] = useState(new Date());
    const [rowLimit, setRowLimit] = useState(DEFAULT_ROW_LIMIT);

    const searchLogs = () => {
        if (loading) {
            return;
        }
        loadLogs({searchString: searchString, logType: logType, searchDate: searchDate, rowLimit: rowLimit});
    };

    const resetSearchData = () => {
        setSearchString("");
        setLogType({value: LOG_S2S});
        setSearchDate(new Date());
        setRowLimit(DEFAULT_ROW_LIMIT);
        resetLogs();
    };

    const renderLogs = () => {
        return (
            <div className="col-12">
                {loading && <Spinner/>}
                {!loading &&
                (!emptyString(logsData)
                        ? <div className="input-group">
                            <div className="api-wrap">
                            <textarea name="ip" id="" rows="15" value={logsData} style={{height: "70vh", fontSize: "13px"}}
                                   onChange={() => {
                                   }} className="log-textarea" readOnly={false}/>
                            </div>
                          </div>
                        : <div/>
                )
                }
            </div>
        )
    };

    return <div id="custom-nav-home" role="tabpanel"
                aria-labelledby="custom-nav-home-tab" style={{height: '85vh'}}>
        <div className="row">
            <div className="col-lg-2 col-md-6 col-sm-12">
                <MaterialUISelect
                    label="Тип"
                    dropdownData={[{value: LOG_S2S}]}
                    selectedValue={logType}
                    onSelectCallbackFunction={setLogType}
                    dataField='value'
                    displayedField='value'
                    isClearable={true}
                    styles={{root: {minWidth: "150px"}, input: {height: "30px"}}}
                />
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
                <DatePicker date={searchDate} onSelectDate={setSearchDate}/>
            </div>
            <div className="col-lg-1 col-md-2 col-sm-12">
                <MaterialUiInput placeholder="-" label="Строк" value={rowLimit} onTextTyping={setRowLimit}
                                 styles={{root: {minWidth: 80}}}/>
            </div>
            <div className="col-lg-7 col-md-10 col-sm-12" title="Если необходимо произвести поиск по нескольким совпадениям, то их необходимо разделить символом '+'. Пример: UNI3+Finish+videomir">
                <MaterialUiInput placeholder="-" label="Строка поиска" value={searchString} onTextTyping={setSearchString}
                                 styles={{root: {minWidth: 150}}}/>
            </div>
        </div>
        <CircleButtonsGroup buttons={
            [
                {
                    isSelected: false,
                    title: "Найти",
                    iconClassName: "ti ti-search stat-icon",
                    onClick: () => searchLogs()
                },
                {
                    isSelected: false,
                    title: "Очистить",
                    iconClassName: "ti ti-close stat-icon",
                    onClick: () => resetSearchData()
                }
            ]
        }/>
        {renderLogs()}
    </div>

}

const mapStateToProps = (state) => {
    return {
        loading: state.logsReducer.loading,
        logsData: state.logsReducer.logsData,
    }
};

export default connect(mapStateToProps, {
    loadLogs,
    resetLogs
})(LogsContent);