import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import {
    clearAppParams,
    deleteAppParam,
    loadAllTypes,
    loadAppParams,
    saveOrUpdateAppParam
} from "../../../../actions/appParamsActions";
import AppParamsPopover from "./AppParamsPopover";
import ActionPopover from "../../../common/popovers/ActionPopover";
import SimpleTable from "../../../common/tables/SimpleTable";
import {getRusDateHourString} from "../../../../utils/dateUtils";
import Spinner from "../../../common/Spinner";


function AppParamsContent({appParamTypes, loadAllTypes, appParamsLoading, appParams, loadAppParams, saveOrUpdateAppParam, deleteAppParam, clearAppParams}) {


    const [projectTypeDropdownData, setProjectTypeDropdownData] = useState(null);
    const [paramTypeDropdownData, setParamTypeDropdownData] = useState(null);
    const [projectType, setProjectType] = useState(null);
    const [paramType, setParamType] = useState(null);

    useEffect(() => {
        loadAllTypes();
    }, []);

    useEffect(() => {
        if (!appParamTypes) {
            return;
        }
        const uniqueItems = new Set();
        const projectTypeDropdownData = appParamTypes.map(paramType => {
            return {value: paramType.projectType}
        }).filter(item => {
            if (!uniqueItems.has(item.value)) {
                uniqueItems.add(item.value);
                return true;
            }
            return false;
        });
        setProjectTypeDropdownData(projectTypeDropdownData);
    }, [appParamTypes]);

    useEffect(() => {
        if (!projectTypeDropdownData|| !projectType || !appParamTypes) {
            setParamType(null);
            return;
        }
        const paramTypeDropdownData = appParamTypes.filter(appParamType => appParamType.projectType === projectType.value);
        setParamTypeDropdownData(paramTypeDropdownData);
    }, [projectTypeDropdownData, projectType, appParamTypes]);

    useEffect(() => {
        if (!projectType?.value || !paramType?.type) {
            clearAppParams();
            return;
        }
        loadAppParams(projectType.value, paramType.type);
    }, [paramType, projectType]);

    const saveOrUpdate = (appParam) => {
        saveOrUpdateAppParam(appParam, () => loadAppParams(projectType.value, paramType.type));
    };

    const deleteParam = (appParam) => {
        appParam.projectType = projectType?.value;
        deleteAppParam(appParam, () => loadAppParams(projectType.value, paramType.type));
    };

    const tableColumns = () => {
        return [
            {
                name: "Значение",
                key: "data",
                getBodyCellValue: item => item["data"],
                headerCellStyle: {minWidth: "150px"},
                bodyCellStyle: {textAlign: "center"},
            },
            {
                name: "Дата добавления",
                key: "creationTime",
                getBodyCellValue: item => item["creationTime"] && getRusDateHourString(new Date(item["creationTime"])),
                headerCellStyle: {minWidth: "150px"},
                bodyCellStyle: {textAlign: "center"},
            },
            {
                name: "Дата редактирования",
                key: "updateTime",
                getBodyCellValue: item => item["updateTime"] && getRusDateHourString(new Date(item["updateTime"])),
                headerCellStyle: {minWidth: "150px"},
                bodyCellStyle: {textAlign: "center"},
            },
            {
                name: "Комментарий",
                key: "comment",
                getBodyCellValue: item => item["comment"],
                headerCellStyle: {minWidth: "150px"},
                bodyCellStyle: {textAlign: "center"},
            },
            {
                name: renderParamSavePopover(<i className="ti ti-plus" style={{cursor: "pointer", fontSize: "18px"}} title="Добавить"/>),
                key: "actions",
                getBodyCellValue: (item) => <div style={{display: "flex"}}>
                    {renderParamSavePopover(<i className="ti ti-marker-alt" style={{cursor: "pointer", fontSize: "18px"}}  title="Редактировать"/>, item)}
                    {<ActionPopover
                        button={<i className="ti ti-trash" style={{cursor: "pointer", fontSize: "18px"}}/>}
                        tooltip="Удалить"
                        popoverLocation='right'
                        label="Удалить ?"
                        handleSubmit={() => deleteParam(item)}/>}
                </div>,
                headerCellStyle: {minWidth: "100px", width: "100px"},
                bodyCellStyle: {textAlign: "left"},
                isSortingDisabled: true,
            },
        ]
    };

    const renderParamSavePopover = (button, appParam) => {
        return <AppParamsPopover button={button}
                                 appParam={appParam}
                                 projectType={projectType} setProjectType={setProjectType}
                                 projectTypeDropdownData={projectTypeDropdownData}
                                 paramType={paramType} setParamType={setParamType}
                                 paramTypeDropdownData={paramTypeDropdownData}
                                 saveOrUpdate={saveOrUpdate}
        />
    };

    return <div id="custom-nav-home" role="tabpanel"
                aria-labelledby="custom-nav-home-tab" style={{height: '85vh'}}>
        <div className="row">
            <div className="col-lg-6">
                <MaterialUISelect
                    label="Проект"
                    dropdownData={projectTypeDropdownData}
                    selectedValue={projectType}
                    onSelectCallbackFunction={setProjectType}
                    dataField='value'
                    displayedField='value'
                    isClearable={true}
                    styles={{root: {minWidth: "150px"}, input: {height: "30px"}}}
                />
            </div>
            <div className="col-lg-6" title={paramType?.description}>
                <MaterialUISelect
                    label="Тип"
                    dropdownData={paramTypeDropdownData}
                    selectedValue={paramType}
                    onSelectCallbackFunction={setParamType}
                    dataField='type'
                    displayedField='name'
                    isClearable={true}
                    styles={{root: {minWidth: "150px"}, input: {height: "30px"}}}
                />
            </div>
            {paramType?.description && <div><p className="alert"><i className="ti ti-info-alt"/>{"  " + paramType?.description}</p></div>}

        </div>
        {appParamsLoading && <Spinner/>}
        {!appParamsLoading && <div className="responsive-block statistic_table_wrapper" id="fullWidth" style={{minHeight: '30vh', maxHeight: '70vh'}}>
            <SimpleTable
                tableClassName="table table-bordered nowrap statistic_table sticky-header sticky-action sticky-footer userselecttext"
                columns={tableColumns()}
                data={appParams}
                bodyRowKey="id"/>
        </div>}

    </div>;
}

const mapStateToProps = (state) => {
    return {
        appParamTypesLoading: state.appParamsReducer.appParamTypesLoading,
        appParamTypes: state.appParamsReducer.appParamTypes,
        appParamsLoading: state.appParamsReducer.appParamsLoading,
        appParams: state.appParamsReducer.appParams,
    }
};

export default connect(mapStateToProps, {
    loadAllTypes,
    loadAppParams,
    saveOrUpdateAppParam,
    deleteAppParam,
    clearAppParams
})(AppParamsContent);