import React, {Fragment} from "react";
import ActionPopover from "../popovers/ActionPopover";
import CircleButton from "./CircleButton";

export default function CircleButtonsGroup({
                                               buttons = [{
                                                   isSelected: false,
                                                   title: "",
                                                   confirmMessage: null,
                                                   iconClassName: "",
                                                   onClick: () => {
                                                   },
                                                   customBtn: null
                                               }]
                                           }) {
    return (
        <div className="circle-btn-group">
            {buttons.map(btn => <Fragment key={btn.title}>
                    {
                        btn.customBtn
                            ? btn.customBtn
                            : btn.confirmMessage
                            ? <ActionPopover
                                button={
                                    <CircleButton iconClassName={btn.iconClassName} title={btn.title}
                                                  isSelected={btn.isSelected}/>
                                }
                                tooltip={btn.confirmMessage}
                                popoverLocation='top'
                                label={btn.confirmMessage}
                                handleSubmit={btn.onClick}/>
                            : <CircleButton iconClassName={btn.iconClassName} title={btn.title} isSelected={btn.isSelected}
                                            onClick={btn.onClick}/>
                    }
                </Fragment>
            )}
        </div>
    )
}


