import {
    APP_PARAM_TYPE_SAVED,
    APP_PARAM_TYPES_LOADED,
    APP_PARAM_TYPES_LOADING, APP_PARAMS_CLEARED,
    APP_PARAMS_LOADED,
    APP_PARAMS_LOADING
} from "./actionTypes";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnErrors} from "./errorActions";
import {getSuccess} from "./successActions";

export const loadAllTypes = () => (dispatch, getState) => {
    dispatch({type: APP_PARAM_TYPES_LOADING});
    axios
        .get(process.env.REACT_APP_API_URL + '/appParams/types', getConfig(getState))
        .then(res =>
            dispatch({
                type: APP_PARAM_TYPES_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const loadAppParams = (projectType, paramType) => (dispatch, getState) => {
    dispatch({type: APP_PARAMS_LOADING});
    axios
        .get(process.env.REACT_APP_API_URL + '/appParams?projectType=' + projectType + '&paramType=' + paramType, getConfig(getState))
        .then(res =>
            dispatch({
                type: APP_PARAMS_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const saveOrUpdateAppParam = (body, onSuccessCallback) => (dispatch, getState) => {
    axios
        .post(process.env.REACT_APP_API_URL + '/appParams/save', body, getConfig(getState))
        .then(res =>
            dispatch({
                type: APP_PARAM_TYPE_SAVED,
                payload: res.data
            })
        )
        .then(() => {
            dispatch(getSuccess({success: "Параметр сохранён"}))
        })
        .then(() => {
            onSuccessCallback && onSuccessCallback()
        })
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const deleteAppParam = (body, onSuccessCallback) => (dispatch, getState) => {
    axios
        .post(process.env.REACT_APP_API_URL + '/appParams/delete', body, getConfig(getState))
        .then(res =>
            dispatch({
                type: APP_PARAM_TYPE_SAVED,
                payload: res.data
            })
        )
        .then(() => {
            dispatch(getSuccess({success: "Параметр удалён"}))
        })
        .then(() => {
            onSuccessCallback && onSuccessCallback()
        })
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const clearAppParams = () => (dispatch, getState) => {
    dispatch({type: APP_PARAMS_CLEARED});
};